.main {
  padding: 2rem 0;
  background-color: #f4f4f4;
  font-family: 'Titillium Web', sans-serif;
}

.intro {
  padding: 5rem 0 ;
}

.intro h3 {
  font-family: 'Hind Vadodara';
  font-weight: 400;
  font-size: 1.9rem;
  line-height: 1.8;
  text-align: left;
}

.bold {
  font-weight: 600;
}

.self-pic {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
}


.work h2 {
  font-family: 'Hind Vadodara';
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.project-card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  transition: transform 0.2s, box-shadow 0.2s;
  flex-grow: 1;
  margin-top: 4rem;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.project-photo {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.card-body {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.project-title {
  font-family: 'Hind Vadodara';
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.project-desc {
  font-family: 'Hind Vadodara';
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 1rem;
  flex-grow: 1;
}

.project-tag {
  background-color: black;
  color: aliceblue;
  margin: 0.2rem;
  padding: 0.4rem 0.8rem;
  font-size: 0.875rem;
  border-radius: 4px;
}

.project-tag:hover {
  background-color: #777;
}
