
.portfolio {
  object-position: center;
  text-align: center;
  /* min-height: 95vh; */
  /* min-height: -webkit-fill-available; */
  width: 100%;
  background-color: rgb(244, 244, 244);
  font-family: 'Titillium Web', sans-serif;
}

.portfolio h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 3rem 0;
  font-family: 'Hind Vadodara';
  font-weight: 600;
  font-size: 4rem;
  color: white
}

.portfolio h3 {
  font-family: 'Hind Vadodara';
  font-weight: 100;
  font-size: 2rem;
  margin: 0;
  padding: 3rem 0 3rem 0;

}

.portfolio h4 {
  font-family: 'Hind Vadodara';
  font-weight: 100;
  font-size: 2rem;
  color: gray;
  padding: 0em 0 1rem 0;
  margin: 0;
  line-height: 2;
}

.portfolio a {
  font-family: 'Hind Vadodara';
  font-weight: 100;
  font-size: 2rem;
  color: gray;
  padding: 0em 0 1rem 0;
  margin: 0;
  text-decoration: none;
}

@media only screen and (max-width: 1000px) {
  .portfolio {
    object-position: center;
    text-align: center;
    min-height: auto;
    /* min-height: -webkit-fill-available; */
    width: 100%;
    background-color: rgb(244, 244, 244);
    font-family: 'Titillium Web', sans-serif;
  }
}
