.App {
  text-align: center;
  /* background-color: #A3DCFF; */
  /* background-color: #3d5a80; */
  /* min-height: 100vh; */
  /* min-height: -webkit-fill-available; */
  /* height: 100vh; */
  /* font-family: 'Titillium Web', sans-serif; */
  /* height: 100%; */
  /* position: absolute; */
  /* left: 0;
  width: 100%;
  overflow: hidden; */
  /* background-color: rgb(244, 244, 244); */

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */