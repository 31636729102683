@import url('https://fonts.googleapis.com/css2?family=Hind+Vadodara:wght@300;400;500;600;700&display=swap');

.top_navbar {
    justify-items: center;
    color: aliceblue;
    display: flex;
    justify-content: space-between;
    font-family: 'Hind Vadodara', sans-serif;
    font-weight: 300;
    font-size: 1.8rem;
    height: 5vh;
    color: #293241;
    /* background-color: #002fa7; */
    background-color: #fff;
    align-items: center;
}

.navbar_right {
    /* justify-items: auto;
    color: aliceblue;
    flex: 12;
    margin: auto !important; */
    /* display: flex;
    justify-content: space-around; */
    margin-right: 1rem;
    font-size: 1rem;
}

.name {
    margin-left: 1rem;
}


.inactive {
    color: #888f8f;

    text-decoration: none;
    /* margin-left: 1rem; */

}

.active {
    color: #303335;

    text-decoration: none;
}

li {
    display: inline-block;
    /* height: 50%; */
    /* padding: 20px 0 20px; */
}

.navlink {
    text-decoration: none;
    position: relative;
    display: block;
    transition: color 0.1s, background-color 0.1s;
    /* color: #000; */
}

.navlink::before {
    content: '';
    display: block;
    position: absolute;
    top: 90%;
    height: 2px;
    width: 85%;
    /* padding-right: 1em; */
    background-color: #303335;
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    transition: color 0.1s, -webkit-transform 0.2s ease-out;
    transition: color 0.1s, transform 0.2s ease-out;
    transition: color 0.1s, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.navlink:active::before {
    background-color: #000000;
}


.navlink:hover::before,
.navlink:focus::before {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.dropDownIcon {
    display: none;
}




@media screen and (max-width: 800px) {
    .top_navbar {
        justify-items: center;
        color: aliceblue;
        display: flex;
        justify-content: space-between;
        font-family: 'Hind Vadodara', sans-serif;
        font-weight: 300;
        font-size: 1rem;
        height: auto;
        color: #293241;
        /* background-color: #002fa7; */
        background-color: #fff;
        align-items: center;
        height: 7vh;

    }

    .navbar_right {
        margin-right: 0.5rem;
        opacity: .2;
        margin-top: 1rem;
        pointer-events: none;
        display: none;
    }

    .navlink {
        font-size: 1.5rem;
        padding: 0.25rem;
        font-weight: 400;
    }

    .dropDownMenu {
        /* display: block; */
        top: 0;
        /* margin-right: 1rem; */


        button {
            top: 0;
            right: 0;
            display: block;
            width: 8vw;
            height: 2vh;
            transition: all 300ms cubic-bezier(.61, .01, .42, 1);
            cursor: pointer;
            background: transparent;
            border: 0px;
            margin: 0;
            /* margin: 2rem 2rem; */
            /* margin-top: 2rem; */
            position: fixed;
            margin-right: 2vw;
        }

        /* .navbar_right {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: 0;
            margin-top: 15rem;
            margin-right: 2rem;
            pointer-events: none;
            font-size: 2rem;
        } */

        div.icon {
            height: 0px;
            border: 1.5px solid #000000;
            width: 22px;
            /* display: block; */
            position: absolute;
            transition: all 300ms cubic-bezier(.61, .01, .42, 1);
            background: #000000;
            margin-top: 2vh;
            margin-right: auto;
            /* padding-top: 2rem; */
        }

        button:hover {
            transition-delay: 100ms;
            transform: scale(1.1);
            right: 0;
            /* margin-top: 2rem; */
        }

        button:hover div:nth-child(3):before {
            width: 2px;
            height: 2px;
            border-radius: 50%;
            background: #000000;
        }

        button:hover div.icon {
            border: 1.5px solid #000000;
            height: 9px;
            border-radius: 50%;
            margin-left: -1px;
            animation: atom 300ms linear 1;
            position: absolute;
            display: block;
            width: 25px;
            top: 0px;
            background: transparent;
            /* margin-top: 20rem; */
        }

        button:focus {
            outline: 0px;
        }

        div:nth-child(1) {
            top: 0px;
        }

        div:nth-child(2) {
            top: 8px;
        }

        div:nth-child(3) {
            top: 16px;
        }

        div:nth-child(3):before {
            opacity: 0;
            animation: ball 1.5s linear infinite;
            content: '';
            border: 2px solid #000000;
            display: block;
            position: relative;
            top: 0.25px;
        }

        button:hover div:nth-child(1) {
            transform: rotate(-33deg);
        }

        button:hover div:nth-child(2) {
            transform: rotate(90deg);
        }

        button:hover div:nth-child(3) {
            transform: rotate(33deg);
        }

        button:hover div:nth-child(3):before {
            opacity: 0;
            transition: opacity 600ms cubic-bezier(.61, .01, .42, 1);
        }

        button:active:hover div:nth-child(3):before,
        button:active div:nth-child(3):before,
        button:active div:nth-child(2) {
            opacity: 0;
            transition: all 200ms;
        }

        button:active div.icon {
            border: 1.5px solid #000000;
            height: 0px;
            border-radius: 0%;
            margin-left: -1px;
            margin-top: 6px;
            animation: division 300ms linear 1;
            width: 25px;
            top: 0px;
            margin-top: 2rem;


        }

        button:active div:nth-child(2) {
            width: 0px;
        }

        button:active div:nth-child(3) {
            transform: rotate(45deg);
        }

        button:active div:nth-child(1) {
            transform: rotate(-45deg);
        }

        @keyframes atom {
            0% {
                transform: rotate(180deg);
            }
        }

        @keyframes division {
            0% {
                transform: rotate(180deg);
            }
        }

        @keyframes ball {
            0% {
                left: -20%;
                top: 10%;
            }

            10% {
                left: 10%;
                top: -35%;
            }

            25% {
                left: 45%;
                top: -50%;
            }

            40% {
                left: 80%;
                top: -20%;
            }

            50% {
                left: 98%;
                top: 18%;
            }

            60% {
                left: 80%;
                top: 50%;
            }

            75% {
                left: 45%;
                top: 80%;
            }

            90% {
                left: 0%;
                top: 60%;
            }

            100% {
                left: -20%;
                top: 10%;
            }
        }
    }


    .dropDownMenu:hover {
        .navbar_right {
            opacity: 1;
            pointer-events: auto;
            display: flex;
            position: relative;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #ffffff;
            /* margin-right: 2rem; */
            margin-top: 15rem;
            font-size: 2rem;
            border-radius: 5%;
            padding: 0 2rem ;
            z-index: 5;
        }

        li {

        }
    }

}






@media screen and (min-width: 800px) {
    .top_navbar {
        justify-items: center;
        color: aliceblue;
        display: flex;
        justify-content: space-between;
        font-family: 'Hind Vadodara', sans-serif;
        font-weight: 300;
        font-size: 1.3rem;
        color: #293241;
        background-color: #002fa7;
        background-color: #fff;
        align-items: center;
        padding: 1rem 0 1rem 0;
        height: 5vh;
    }

    .navbar_right {
        margin-right: 0;
        /* margin-top: 1rem; */
    }

    .navlink {
        font-size: 1.3rem;
        padding: 0 0 0 0.5rem;
        margin-right: 1rem;
    }

    .navlink::before {
        content: '';
        display: block;
        position: absolute;
        top: 90%;
        height: 2px;
        width: 90%;
    }

    .icon {
        margin-left: 1rem;
    }
}