.project-detail {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.project-header {
    text-align: center;
    margin-bottom: 3rem;
}

.project-header h1 {
    font-size: 3rem;
    color: #2d3748;
    margin-bottom: 0.5rem;
}

.project-year {
    color: #718096;
    font-size: 1.2rem;
}

.project-gallery {
    margin-bottom: 3rem;
}

.main-image {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.main-image img {
    width: 100%;
    height: auto;
    display: block;
}

.image-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 1rem;
    margin: 0;
}

.image-thumbnails {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    overflow-x: auto;
    padding: 0.5rem;
}

.image-thumbnails img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s;
}

.image-thumbnails img:hover {
    transform: scale(1.05);
}

.image-thumbnails img.active {
    border: 2px solid #4a90e2;
}

.project-links {
    display: flex;
    gap: 1rem;
    margin-bottom: 3rem;
    justify-content: center;
}

.project-links a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 500;
    transition: transform 0.2s;
}

.github-link {
    background: #24292e;
    color: white;
}

.live-link {
    background: #4a90e2;
    color: white;
}

.project-links a:hover {
    transform: translateY(-2px);
}

.project-content section {
    margin-bottom: 3rem;
}

.project-content h2 {
    color: #2d3748;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
}

.tech-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
}

.tech-tag {
    background: #edf2f7;
    color: #4a5568;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-size: 0.9rem;
}

.project-details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    background: #f8fafc;
    padding: 2rem;
    border-radius: 12px;
}

.detail-item h3 {
    color: #4a5568;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.navigation-links {
    margin-top: 4rem;
    padding-top: 2rem;
    border-top: 1px solid #e2e8f0;
}

.back-link {
    color: #4a90e2;
    text-decoration: none;
    font-weight: 500;
}

@media (max-width: 768px) {
    .project-header h1 {
        font-size: 2rem;
    }

    .project-links {
        flex-direction: column;
    }

    .project-details-grid {
        grid-template-columns: 1fr;
    }
} 