.main {
  object-position: center;
  text-align: center;
  /* height: 100vh; */
  /* min-height: 90vh; */
  /* min-height: -webkit-fill-available; */
  width: 100%;
  background-color: rgb(244, 244, 244);
  font-family: 'Titillium Web', sans-serif;
}




.mySwiper {
  margin: 0;
  height: 80vh;
  /* width: 80vw; */
  position: center;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 10vh;
}


.swiper-pic{
  height: 80%;
  width: 100%;
  position: center;
  object-fit:contain;

}

@media only screen and (max-width: 1000px) {
  .mySwiper {
    margin: 3vh 0 3vh 0 ;
    height: 60vh;
    width: 80vw;
    position: center;
  }
  

}