.game {
    font-family: 'Calibri', sans-serif;
    border: #f5f5f5;
    border-radius: 1%;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    align-items: center;
    text-align: center;
    height: 70vh;
    width: 70vw;
    margin: auto;
    margin-top: 2rem;

}

#status {
    border-width: 1px;
    border-style: solid;
    background-color: rgb(240, 240, 240);
    padding: 0.5rem .5rem;
    font-size: 3vw;
    border-radius: 10px;
    width: 50%;
    margin: auto;
    margin-top: 2rem;
}

.matrix {
    display: inline-grid;
    grid-template-columns: auto auto auto auto auto;
    margin: 2rem;

}


.cell {
    border-width: 1px;
    border-style: solid;
    border-color: rgb(148, 148, 148);
    background-color: rgb(241, 241, 241);
    width: 3rem;
    height: 3rem;
    margin: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', "Calibri", 'Trebuchet MS', sans-serif;
}



.button {
    transition-duration: 0.4s;
    background-color: #6c6c6c;
    border: none;
    color: white;
    padding: 0.8rem 1.4rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    border-radius: 2px;
}

.button:hover {
    background-color: rgb(81, 81, 81);
    color: white;
}

.start {
    margin-top: 2rem;
}

#guess_text {
    margin-left: 1.5rem;
    margin-right: 2rem;
}

.start .guess {
    padding: 1.3rem;
    width: 50%;
    text-align: center;
    margin: 0 auto;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    /* background-color: lightblue; */
    font-size: 1rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    margin: auto;
    font-size: 2.5rem;
    /* padding: 1rem; */

}

input[type=text]:focus {
    border: 3px solid #555;
}

input {
    font-size: 1rem;
    margin-right: 2rem;
    /* margin-left: .5rem; */
}

.grey {
    background-color: rgb(241, 241, 241);

}

.lightgrey {
    background-color: gray;
}

.green {
    background-color: #96bb7c
}

.yellow {
    background-color: #fad586;
}


@media only screen and (max-width: 600px) {

    #status {
        border-width: 1px;
        border-style: solid;
        background-color: rgb(240, 240, 240);
        padding: 0.5rem .5rem;
        font-size: 6vw;
        border-radius: 10px;
        width: 50%;
        margin: auto;
        margin-top: 3rem;
    }


    input[type=text]:focus {
        border: 3px solid #555;
    }
    
    input {
        font-size: .8rem;
        margin: auto;
        margin-top: 2rem;
    }

    .button{
        margin-top: 1.5rem;
    }

    .cell {
        border-width: 1px;
        border-style: solid;
        border-color: rgb(148, 148, 148);
        background-color: rgb(241, 241, 241);
        width: 2.5rem;
        height: 2.5rem;
        margin: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        color: white;
        font-family: 'Gill Sans', 'Gill Sans MT', "Calibri", 'Trebuchet MS', sans-serif;
    }
    .lightgrey {
        background-color: gray;
    }
    
    .green {
        background-color: #96bb7c
    }
    
    .yellow {
        background-color: #fad586;
    }

}