.app-route {
  text-align: center;
  /* background-color: #A3DCFF; */
  /* background-color: #3d5a80; */
  min-height: 90vh;
  max-height: -webkit-fill-available;
  /* min-height: 100vh; */
  
  background-color: rgb(244, 244, 244);

  /* height: 92vh;  */
  font-family: 'Titillium Web', sans-serif;
  position: flex;
  flex-flow: column nowrap;
  /* min-height: 100vh;  */


}

#app-container{
  /* height: 90vh; */
  min-height: 90vh;
  /* position: relative; */
  /* flex-grow: 1; */

}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 800px) {
  #app-container{
    /* height: 90vh; */
    min-height: 86vh;
    /* position: relative; */
    /* flex-grow: 1; */
  
  }
}
