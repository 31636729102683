@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

.footer {
  justify-items: center;
  color: aliceblue;
  display: flex;
  justify-content: space-between;
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 300;
  font-size: 1.2rem;
  height: 5vh;
  color: white;
  background-color: #1c1c1c;
  align-items: center;
  padding-left: 1rem;
  left: 0;
  bottom: 0;
  right: 0;
  /* position: fixed;  */

}

.footer_right a {
  color: aliceblue;
  /* text-decoration: none; */
  margin-right: 1rem;
  padding-right: 1rem;
  font-size: 2rem;
}



@media only screen and (max-width: 800px) {
  .footer {
    height: 7vh;
    font-size: 0.8rem;
    padding: 0.5rem;
    bottom: 0;
  }

  .footer_right a {
    color: aliceblue;
    margin-right: 0;
    padding-right: 1rem;
    font-size: 1rem;
  }
}



/* @media only screen and (max-width: 379px) {
  .footer {
    height: 8%;
    font-size: 0.9rem;
    padding: 0.5rem;
    bottom: 0;
    position: absolute;
  }

  .footer_right a {
    color: aliceblue;
    margin-right: 0.6rem;
    padding-right: 0.7rem;
    font-size: 0.7rem;

  }
}

@media only screen and (min-width: 350px) {
  .footer {
    height: 5%;
    font-size: 0.9rem;
    padding: 0.5rem;
    bottom: 0;
    position: absolute;
  }

  .footer_right a {
    color: aliceblue;
    margin-right: 0.8rem;
    padding-right: 0.7rem;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 500px) {
  .footer {
    height: 5%;
    font-size: 1rem;
    padding: 0.5rem;
    bottom: 0;
    position: absolute;
  }

  .footer_right a {
    color: aliceblue;
    margin-right: 1rem;
    padding-right: 1rem;
    font-size: 1.3rem;
  }
}


@media only screen and (min-width: 600px) {
  .footer {
    height: 4%;
    font-size: 1.3rem;
    padding: 0.5rem;
    bottom: 0;
    position: absolute;

  }

  .footer_right a {
    color: aliceblue;
    margin-right: 1rem;
    padding-right: 1rem;
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 1200px) {
  .footer {
    height: 3rem;
    font-size: 1.3rem;
    padding: 0.5rem;
    bottom: 0;
    position: absolute;
  }

  .footer_right a {
    color: aliceblue;
    margin-right: 1rem;
    padding-right: 1rem;
    font-size: 1.3rem;
  }
} */