.hawaii {
  object-position: center;
  text-align: center;
  height: 95vh;
  width: 100%;
  background-color: #313131;
  font-family: 'Titillium Web', sans-serif;
}

.hawaii h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 10rem; */
  font-family: 'Hind Vadodara';
  font-weight: 600;
  font-size: 3rem;
  color: white
}

.hawaii h3 {
  font-family: 'Hind Vadodara';
  font-weight: 100;
  font-size: 2rem;
  color: gray;
  margin: 0 0 0 0;

}

.wheel{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 0;
  padding-top: 5rem;
  font-family: 'Hind Vadodara';
  font-weight: 100;
}