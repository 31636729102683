.contact {
  object-position: center;
  text-align: center;
  min-height: 90vh;
  /* min-height: -webkit-fill-available; */
  width: 100%;
  background-color: rgb(244, 244, 244);
  font-family: 'Titillium Web', sans-serif;
}

.contact h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Hind Vadodara';
  font-weight: 600;
  font-size: 4rem;
  color: white
}

.contact h3 {
  font-family: 'Hind Vadodara';
  font-weight: 100;
  font-size: 2rem;
  margin: 0;
  padding: 3rem 0 0rem 0;

}

.contact a {
  font-family: 'Hind Vadodara';
  font-weight: 100;
  font-size: 2rem;
  color: black;
  padding: 0em 0 1rem 0;
  margin: 0;
  text-decoration: none;
}

.contact h4 {
  font-family: 'Hind Vadodara';
  font-weight: 100;
  font-size: 2rem;
  color: rgb(59, 53, 53);
  padding: 10vh 10vw;
  text-align:justify;
  line-height: 1.6;

}

.contact h4:hover {
  color: rgb(59, 53, 53);
}

.contact .brick {
  width: 50vw;
}

.contact #image-caption {
  text-align: center;
  font-style: italic;
  color: #666;
  margin-top: 8px;
  font-size: 14px;
  font-family: 'Hind Vadodara';
}

@media screen and (max-width: 500px) {

  .contact h4 {
    font-family: 'Hind Vadodara';
    font-weight: 100;
    font-size: 1.2rem;
    color: rgb(59, 53, 53);
    padding: 10vh 10vw;
    text-align:justify;
    line-height: 1.6;
  
  }

}
  

