.projects-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

.projects-header {
  text-align: center;
  margin-bottom: 4rem;
}

.projects-header h1 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 1rem;
}

.projects-header p {
  color: #718096;
  font-size: 1.2rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;
}

.project-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-image {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.view-details {
  color: white;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.75rem 1.5rem;
  border: 2px solid white;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.project-card:hover .view-details {
  background: white;
  color: #2d3748;
}

.project-card:hover .project-overlay {
  opacity: 1;
}

.project-links {
  display: flex;
  gap: 1rem;
}

.project-link {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-weight: 500;
  text-decoration: none;
  transition: transform 0.2s ease;
}

.project-link:hover {
  transform: translateY(-2px);
}

.project-link.github {
  background: #24292e;
  color: white;
}

.project-link.demo {
  background: #4a90e2;
  color: white;
}

.project-content {
  padding: 1.5rem;
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.project-header h3 {
  font-size: 1.5rem;
  color: #2d3748;
  margin: 0;
}

.project-year {
  color: #718096;
  font-size: 0.9rem;
}

.project-description {
  color: #4a5568;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.project-tech {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tech-tag {
  background: #edf2f7;
  color: #4a5568;
  padding: 0.4rem 0.8rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .projects-page {
      padding: 2rem 1rem;
  }

  .projects-grid {
      grid-template-columns: 1fr;
  }

  .projects-header h1 {
      font-size: 2rem;
  }
}

@media (hover: none) {
  .project-overlay {
      opacity: 1;
      background: rgba(0, 0, 0, 0.5);
  }
}